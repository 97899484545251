<template>
    
    <div class="container">
        
        <div class="row justify-content-center">
            
            <div class="col-md-8">

                <div v-if="isExamAssigned === true">

                    <h3 class="text-center">MasterQuiz-Challenge Session</h3>

                </div>

                <div v-else>
                             
                    <h3 class="text-center">MasterQuiz-Practice Session</h3>
                
                </div>

                <div class="card">

                    <div class="card-header text-center">

                        <div v-if="isExamAssigned === true">

                            <h4>{{quiz.qname}}</h4>
                                
                            <span :style="{color: ' rgb(234, 202, 107)'}">{{user.username}}  VS {{quiz.by_username}}</span>
                            
                            <span class="float-left" 
                            style="color:red; background-color:#fff; border-radius: 5px; margin: 5px; padding: 10px">{{questionIndex}}/{{questions.length}}</span>
                            
                            <span class="float-right" 
                            style="color:red; background-color:#fff; border-radius: 5px; margin: 5px; padding: 10px">{{time}}</span>

                        </div>
                        
                        <div v-else>
                         
                             <h4>{{quiz.qname}}</h4>

                             <span class="float-left" 
                             style="color:red; background-color:#fff; border-radius: 5px; margin: 5px; padding: 10px">{{questionIndex}}/{{questions.length}}</span>

                            <span class="float-right" 
                            style="color:red; background-color:#fff; border-radius: 5px; margin: 5px; padding: 10px">{{time}}</span>

                        </div>

                    </div>

                    <div class="card-body">

                            <div v-for="(question,index) in questions" :key="question.id">

                                <div v-show="index===questionIndex">
                                  
                                    <span style="font-weight:bold; font-size: 16px; font-style:italic"  v-html="question.question"></span>

                                    <ul v-for="choice in question.answers" :key="choice.id">
                                        
                                        <hr width="auto"> 
                                        
                                        <label>
                                                                                       
                                            <input type="radio"

                                            :value="choice.is_correct==true?true:choice.answer"

                                            :name = "index"

                                            v-model = "userResponses[index]"

                                            @click = "choices(question.id, choice.id)"

                                            >

                                            <span v-html="choice.answer"></span>

                                        </label>
                                    
                                    </ul>
                                    
                                </div>   

                            </div>

                            <div v-show="questionIndex!=questions.length">

                            <button class="btn btn-success" @click="next();postuserChoices()" :disabled="!userResponses[questionIndex]">Next</button>

                            <button class="btn btn-primary float-right" @click="next()" :disabled="userResponses[questionIndex]">Skip</button>

                            </div>

                             <div v-show="questionIndex === questions.length">

                                <p>

                                    <center>

                                        Finished!

                                    </center>

                                </p>
                                
                                <p>

                                    <center>

                                        To view Score click on the <strong :style="{color: 'red'}">Submit</strong> button to Submit the test.

                                    </center>

                                </p>
                                <p>

                                    <center>

                                        <strong :style="{color: 'red'}">Note:</strong> If timer stops, test will automatically be submitted.

                                    </center>

                                </p>
                               
                            </div>

                            </div>

                            </div>

                        <div v-show="questionIndex === questions.length">

                            <div v-if="isExamAssigned === true">

                                <div class="card mt-5">
                                        
                                    <div class="card-header text-center">

                                    <p>Submit your Test</p>

                                    </div>

                                    <div class="card-body">
                                        
                                        <p>

                                        <center>
                                            
                                            <a v-bind:href="url_challenge"><button type="button" class="btn btn-info">Submit</button></a>

                                        </center> 
                                    
                                        </p>
                                     
                                    </div>

                                </div>

                            </div>

                            <div v-else>

                                <div class="card mt-5">
                                        
                                    <div class="card-header text-center">

                                        <p>Submit your Test</p>

                                    </div>

                                    <div class="card-body">
                                        
                                        <p>

                                        <center>
                                            
                                            <a v-bind:href="url_self"><button type="button" class="btn btn-info">Submit</button></a>
                                        
                                        </center> 
                                    
                                        </p>
                                    
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            
            </div>

</template>

<script>

var moment = require('moment');

export default {
    
    props:['times','quizid', 'quizDesc', 'quizCat','quizBy', 'catid','quizQuestions', 
    'hasPlayedQuiz',  'userid', 'isExamAssigned', 'user', 'quiz'],

    data(){

        return{

                questions: this.quizQuestions,

                questionIndex: 0,

                userResponses: Array(this.quizQuestions.length).fill(false),

                currentQuestion: 0,

                currentAnswer: 0,

                clock: moment(this.times*60*1000),
               
                isAssigned: this.isexamAssigned,

                quizby: this.quizBy
                
            }

        },

        mounted() {
            
            setInterval(() => {

            this.clock = moment(this.clock.subtract(1, 'seconds'))
            
            }, 1000);

            function setCookie(c_name,value,exdays){var exdate=new Date();exdate.setDate(exdate.getDate() + exdays);var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());document.cookie=c_name + "=" + c_value;}

            function getCookie(c_name){var c_value = document.cookie;var c_start = c_value.indexOf(" " + c_name + "=");if (c_start == -1){c_start = c_value.indexOf(c_name + "=");}if (c_start == -1){c_value = null;}else{c_start = c_value.indexOf("=", c_start) + 1;var c_end = c_value.indexOf(";", c_start);if (c_end == -1){c_end = c_value.length;}c_value = unescape(c_value.substring(c_start,c_end));}return c_value;}

            checkSession();

            function checkSession(){
            var c = getCookie("visited");
            if (c === "yes") {
                alert("You alreday Played this Quiz!");
                window.location.href = `http://masterquiz.in/`;

            } else {
                alert("Click OK to Start!");
            }
            setCookie("visited", "yes", 365); // expire in 1 year; or use null to never expire
            }        
           
        },   
        
        computed: {

            time: function(){
            
            var minsec=this.clock.format('mm:ss');
            
            if(minsec=='00:00'){
                
                // alert(` Submitted! Quiz Result is Ready`)

                if(`${this.isExamAssigned}` == 'true'){

                    window.location.href = `http://masterquiz.in/quiz-Node-result/Challenge-played-MasterQuiz/${this.userid}/${this.quizBy}/${this.quizid}/${this.catid}/${this.quizDesc}/${this.quizCat}`;

                } else {

                    window.location.href = `http://masterquiz.in/stats-Node-quiz-result/Self-played-MasterQuiz/${this.userid}/${this.quizBy}/${this.quizid}/${this.catid}/${this.quizDesc}/${this.quizCat}`; 
                      
                }
            }
                return minsec
            }, 

            url_self() {

            return `http://masterquiz.in/stats-Node-quiz-result/Self-played-MasterQuiz/${this.userid}/${this.quizBy}/${this.quizid}/${this.catid}/${this.quizDesc}/${this.quizCat}`; 
                    
        },

            url_challenge() {

            return `http://masterquiz.in/quiz-Node-result/Challenge-played-MasterQuiz/${this.userid}/${this.quizBy}/${this.quizid}/${this.catid}/${this.quizDesc}/${this.quizCat}`;         
        },

        },

        methods:{

            next(){

                this.questionIndex++

            },

            prev(){

                this.questionIndex--

            },

            choices(question, answer){

                this.currentAnswer = answer,

                this.currentQuestion = question

            },

            score(){

                return this.userResponses.filter((val)=>{

                    return val === true;

                }).length

            },

            postuserChoices(){

                axios.post('/quiz/create',{

                    answerId:this.currentAnswer,

                    questionId:this.currentQuestion,

                    quizId :this.quizid,

                    catId :this.catid,

                    userId :this.userid,

                    isAssigned :this.isexamAssigned,
                    
                    User :this.user,

                }).then((response)=>{
                    
                }).catch((error)=>{
                    alert("Error!")
                });

            }

        }

    }

</script>
